<template>
  <div class="constructor-layout" id="constructor" v-show="show">
    <div class="added-alert">
      <div class="bb-container bb-container--lista">
        <div class="added-alert__center">
          <portal-target name="alerts_constructor_page"></portal-target>
        </div>
      </div>
    </div>

    <!-- Sección app download //////////// -->
    <section class="constructor-layout__app" v-if="list.products.length > 0">
      <banner-download-mobile-apps></banner-download-mobile-apps>
    </section>



    <!-- Sección Constructor //////////// -->
    <section class="constructor-layout__sections">
      <div class="bb-container bb-container--lista">

        <Notification
          v-if="showNotification"
          tipo="error"
          @close-notification="closeNotification()"
        >
          {{ errorMessage }}
        </Notification>

        <!-- Main Banner -->
        <div class="constructor-layout__main-banner">
          <!-- <CtaMainBanner v-if="hasProducts"></CtaMainBanner> -->
          <CtaMainBannerSm parentPage="Constructor - Banner Horitzontal" v-if="hasProducts"></CtaMainBannerSm>
        </div>

        <!-- TODO: NEXTGEN Search -->
        <div class="constructor-layout__search">
          <SearchConstructor></SearchConstructor>
        </div>

        <!-- <add-free-product /> -->
        <!-- <fund-add /> -->
      </div>
    </section>
    <section class="constructor-layout__constructor">
      <div class="bb-container bb-container--lista">
        <!-- Main Banner -->
        <header class="constructor-layout__header">
          <header-constructor />
        </header>

        <!-- Explore -->
        <main class="constructor-layout__explore">
          <explore></explore>
        </main>
      </div>

      <FirstConstructorModal
        v-if="showFirstFullConstructorModal"
        @close="closeModal()"
      ></FirstConstructorModal>
    </section>
  </div>
</template>

<script>
import CtaMainBanner from "./components/CtaMainBanner.vue";
import CtaMainBannerSm from "./components/CtaMainBannerSm.vue";
import BannerDownloadMobileApps from "./components/BannerDownloadMobileApps";
import HeaderConstructor from "./components/HeaderConstructor";
import AddFreeProduct from "./components/AddFreeProduct";
import FundAdd from "./components/FundAdd";
import Explore from "./components/Explore";
import CanGetOwnerList from "../../Mixins/CanGetOwnerList";
import { mapGetters } from "vuex";
import Alert from "../../components/ui/Alert.vue";

// Nou Search
import SearchConstructor from "./components/SearchConstructor";
import SearchConstructorResultados from "./components/SearchConstructorResultados";
import Notification from "../../components/ui/Notification.vue";

import FirstConstructorModal from "./components/FirstConstructorModal.vue";

export default {
  name: "ConstructorPage",
  mixins: [CanGetOwnerList],
  components: {
    Notification,
    BannerDownloadMobileApps,
    HeaderConstructor,
    AddFreeProduct,
    FundAdd,
    Explore,
    CtaMainBanner,
    CtaMainBannerSm,
    Alert,
    SearchConstructor,
    SearchConstructorResultados,
    FirstConstructorModal,
  },
  metaInfo() {
    return {
      title: "Construye tu lista | HelloBB",
    };
  },
  data() {
    return {
      show: false,
      error: this.$route.params.error || false,
      errorMessage: this.$route.params.message || "",
      showNotification: this.$route.params.message || "",
      showFirstFullConstructorModal: false,
    };
  },
  computed: {
    ...mapGetters({
      listConstructor: "listConstructor",
      limit: "limit",
    }),
    hasProducts() {
      if (this.list) {
        if (this.list.products) {
          return this.list.products.length > 0;
        }
      }
      return false;
    },
  },
  async serverPrefetch() {
    //await this.$store.dispatch("getListConstructor");
    //console.log("serverPrefetch", this.$store.state.listConstructor)
  },
  async mounted() {
    console.log("mounted", this.$store.state.limit);
    console.log("mounted", this.$store.state.listConstructor);
    await this.$store.dispatch("setLimitSectionsToShow");
    // if (this.$utils.isEmpty(this.listConstructor)) {
    //   await this.$store.dispatch("getListConstructor");
    // }
    await this.$store.dispatch("getListConstructor");
    this.show = true;
    this.$store.commit("setCanScroll");

    const modalShownCookie = this.getCookie("modalShown");
    console.log({ modalShownCookie });
    console.log({ result: modalShownCookie && modalShownCookie !== "undefined" });
    if (modalShownCookie && modalShownCookie !== "undefined") {
      this.showFirstFullConstructorModal = true;
    }
  },
  methods: {
    closeNotification() {
      this.errorMessage = "";
      this.showNotification = false;
    },
    closeModal() {
      this.showFirstFullConstructorModal = false;
      this.removeCookie("modalShown");
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts.pop().split(";").shift();
      }
    },
    removeCookie(name) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    },
  },
  created() {
    if (this.error) {
      this.showNotification = true
    }
  },
};
</script>
